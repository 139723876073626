<template>
  <div class="about">
    <div
        class="about-video"
    >
      <vue-core-video-player
          :src="videoSrc"
          :loop="true"
          :autoplay="true"
          :logo="videoLogo"
          :cover="videoBg"
      ></vue-core-video-player>
    </div>
    <div class="about-section">
        <h2 class="title">About Us</h2>
        <div class="about-block">
          <div class="about-block-top">
            <div class="container">
              <div>
                <p class="text-item">
                  CE Consumer Electronics offers a wide range of high-quality products for your home or office. With over 20
                  years of experience in consumer electronics, we have created devices that are affordable and easy to use. We
                  go into the unknown in search of innovations that will assist people all around the world in living better,
                  healthier lives.That’s why the efforts of the company's specialists have always been focused on the
                  development of versatile devices with an optimal set of functions.
                </p>
                <p class="text-item"> Our mission is to create concepts that improve people's lives by making them more convenient, safer and
                  more interesting. We transfer our ideas into a variety of products - audio and video electronics,
                  information and communication equipment, smart home components - everything that improves the quality of
                  life for everyone. We work hard to create intangible values, providing reliability and safety, confidence
                  and comfort.
                </p>
              </div>
              <div>
                <img src="../assets/images/img/about-1.png" alt="img">
              </div>
            </div>
          </div>
          <div class="about-block-center">
            <div class="container">
              <div>
                <img src="../assets/images/img/about-2.png" alt="img">
              </div>
              <div>
                <p class="text-item">
                  We also understand how important our contribution to maintaining the ecological balance of the planet is.
                  Therefore, environmental protection is one of the priorities of our activity.
                </p>
                <p class="text-item">
                  We believe that ethical governance is not only a way to respond to rapid changes in
                  the global business environment, but also a tool to build trust with our stakeholders, including
                  customers, shareholders, employees, business partners and local communities. That’s why we continue to
                  educate our employees and use monitoring systems, applying fair and transparent corporate governance
                  practices.
                </p>
              </div>
            </div>
          </div>
          <div class="about-block-bottom">
            <div class="container">
              <div>
                <p class="text-item">
                  Our main principle is to use human and technological resources of the company to create products and
                  services of excellent quality, thereby contributing to the improvement of the state of society around the
                  world. That is why CE Consumer Electronics attaches great importance to people and technology.
                </p>
              </div>
              <div>
                <img src="../assets/images/img/about-3.png" alt="img">
              </div>
            </div>
          </div>
        </div>
      </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/components/Nav/Footer";
export default {
  name: "About",
  components: {Footer},
  data (){
    return{
      videoSrc: require('@/assets/video/about-video.mp4'),
      videoLogo: require('@/assets/images/ce-logo.png'),
      videoBg: require('@/assets/images/img/header-bg.png')
    }
  },
  mounted() {
    window.scrollTo(0,0)
  },
}
</script>
<style lang="scss">
@import "@/assets/scss/_vars.scss";
  .about{
    .about-video{
      width: 100%;
      height: 609px;
    }
    .about-section{
      background-color: $white;
      overflow: hidden;
        .title{
          padding-top: 60px;
          color: $titleText;
          text-align: center;
        }
        .about-block{
          margin-top: 41px;
          .about-block-top{
            .container{
              width: 100%;
              display: flex;
              justify-content: flex-end;
              & > div:nth-child(1){
                max-width: 555px;
                width: 100%;
                margin-right: 88px;
                p{
                  color: $homeTextBlack;
                  margin-top: 20px;
                  &:first-child{
                    margin-top: 31px;
                  }
                }
              }
              & > div:nth-child(2){
                max-width: 642px;
                width: 100%;
                height: 498px;
                & > img{
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
          .about-block-center{
            .container{
              display: flex;
              justify-content: space-between;
              & > div{
                &:nth-child(1){
                  margin: 102px 0 122px 89px;
                  max-width: 412px;
                  width: 100%;
                  height: 273px;
                  & > img{
                    width: 100%;
                    object-fit: cover;
                  }
                }
                &:nth-child(2){
                  max-width: 636px;
                  margin: 134px 187px 0 0;
                  & > p{
                    color: $homeTextBlack;
                    margin-top: 20px;
                    &:nth-child(1){
                      margin-top: 0;
                    }
                  }
                }
              }
            }
          }
          .about-block-bottom{
            .container{
              display: flex;
              justify-content: flex-end;
              & > div{
                &:nth-child(1){
                  max-width: 555px;
                  width: 100%;
                  margin: 127px 92px 0 0;
                  & > p{
                    color: $homeTextBlack;
                  }
                }
                &:nth-child(2){
                  max-width: 642px;
                  width: 100%;
                  height: 358px;
                  & > img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
              }
            }
          }
          & > div{
            &:hover{
              transform: scale(1.05, 1.05);
              background-image: linear-gradient(126.08deg, #005AA3 6.14%, #002659 85.23%);
              transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
              cursor: pointer;
              transition-duration: 300ms;
              transition-delay: 150ms;
              p{
                color: $white !important;
              }
            }
          }
        }

    }
  }
@media (max-width: 1400px) {
  .about{
    .about-video{
      height: 509px;
    }
    .about-section{
      .about-block{
        .about-block-top{
          .container{
            & > div{
              &:nth-child(1){
                margin-right: 0;
                margin-left: 30px;
              }
              &:nth-child(2){
                margin-right: 30px;
                height: 410px;
              }
            }
          }
        }
        .about-block-center{
          .container{
            & > div{
              &:nth-child(1){
                margin: 65px 0 0 65px;
              }
              &:nth-child(2){
                margin: 65px 187px 0 0;
              }
            }
          }
        }
        .about-block-bottom{
          .container{
            & > div{
              &:nth-child(1){
                margin-left: 30px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .about{
    .about-video{
      height: 450px;
    }
  .about-section{
    .about-block{
      .about-block-top{
        .container{
          align-items: center;
          & > div{
            margin-bottom: 20px;
            &:nth-child(1){
              & > p:first-child{
                margin-top: 0 !important;
              }
            }
          }
        }
      }
      .about-block-center{
        .container{
          & > div{
            &:nth-child(1){
              max-width: 240px;
              margin: 50px 0 0 20px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            &:nth-child(2){
              margin: 50px 25px 0 0;
            }
          }
        }
      }
      .about-block-bottom{
        .container{
          & > div{
            &:nth-child(1){
              margin: 90px 60px 0 20px;
            }
          }
        }
      }
    }
  }
  }
}
@media (max-width: 992px) {
  .about{
    .about-video{
      height: 430px;
    }
    .about-section{
      .about-block{
        .about-block-top{
          .container{
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            & > div{
              margin-bottom: 20px;
              &:nth-child(1){
                max-width: 768px;
                margin-right: 30px;
              }
              &:nth-child(2){
                height: 300px;
              }
            }
          }
        }
        .about-block-center{
          .container{
            flex-wrap: wrap;
            & > div{
              &:nth-child(1){
                max-width: 260px;
                margin: 20px auto 20px;
              }
              &:nth-child(2){
                max-width: 100%;
                margin: 50px 25px 50px 25px;
              }
            }
          }
        }
        .about-block-bottom{
          .container{
            flex-wrap: wrap;
            & > div{
              &:nth-child(1){
                margin: 50px 30px 20px 30px;
                max-width: 100%;
              }
              &:nth-child(2){
                margin: 0 auto 50px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .about {
    .about-video {
      height: 325px;
    }
    .about-section{
      .title{
        padding-top: 30px;
      }
      .about-block{
        .about-block-top{
          .container{
            & > div:nth-child(2){
              margin-right: 0;
            }
          }
        }
        .about-block-center{
          .container{
            & > div:nth-child(1){
              margin: 0 auto;
            }
            & > div:nth-child(2){
              margin: 25px 20px;
            }
          }
        }
        .about-block-bottom{
          .container{
            & > div:nth-child(1){
              margin: 25px 20px;
            }
          }
        }
      }
    }
  }
}
</style>